export default {
  formTitle: "Sofortkredit – Ihr Wunschkredit schnell auf Ihrem Konto",
  formSubTitle: "Sofortkredit ohne Schufa Auskunft",
  heading: "Sofortauszahlung in 5 Min.",
  subHeading: "100% Kostenlos und Unverbindlich",
  notice: `Mit einem Sofortkredit kommen Sie schnell und unkompliziert an Bargeld. Es entfallen die meist zeitraubenden und unüberschaubaren Formalitäten. Die NORICUS Finanz kann hier zu überzeugenden Konditionen vermitteln.

Sofortkredite werden in der regel innerhalb kürzester Zeit unbürokratisch ausgezahlt. Komplizierte Gespräche mit Banken und deren Beratern entfallen hier.`,
  itemsTitle: "Ein Sofortkredit bietet Ihnen folgende Vorteile:",
  items: [
    	
	"Angebotserstellung ist für Sie 100% kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
    "Die Antragsprüfung und Entscheidung erfolgt sofort online!",
    "Zeitraubende Bankbesuche entfallen",
    "Sofortige Antragsannahme ist garantiert!",
    "Schnell, Seriös, diskret & anonym",
    "Betrag meistens innerhalb von 24 Stunden verfügbar.",
    "Machen Sie mit Ihrem Geld was Sie wollen! (Keine Zweckbindung der Kredite)",
    "Unser freundlicher Kundenservice berät Sie professionell bei allen Anliegen",
    "Laufzeiten flexibel wählbar",
    "Individuell nach Ihrem Wunsch: Zinssatz fest oder flexibel während der Laufzeit",
    "Ohne aufwändige Vertreterbesuche, alles wird auf dem Postweg erledigt",
  ],
  tncText: "*kostenlos & unverbindlich (Wie es der Rechtslage entspricht)",
  btnText: "Jetzt Antrag erstellen*",
  repsTitle: "REPRÄSENTATIVEN BEISPIEL",
  repsExample: `*Angaben gem. § 6a PAngV: <br/><br/>Unveränderlicher Sollzinssatz ab 3,92%, effektiver Jahreszins ab 3,99% - 15,99%, Nettodarlehensbetrag ab 1.000,- bis 50.000,- €, Laufzeit von 12 bis 120 Monaten, Bonität vorausgesetzt. Bei einem Nettodarlehensbetrag von 10.000,- € und einer Laufzeit von 36 Monaten erhalten 2/3 der Kunden von Sofort24Kredit vorraussichttlich einen eff. Zinssatz von 8,90% oder günstiger (geb. Sollzinssatz 5,26%)."`,
}
